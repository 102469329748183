import Image from "next/image";
import Link from "next/link";
import { getTopCategoriesAndCoins } from "../../server/api/methods";
import { useQuery } from "@tanstack/react-query";

export default function GridList() {
  // const cat = trpc.mysql.getAllCategories.useQuery();
  // const categories = trpc.mysql.getCategoriesLimit.useQuery(200).data || [];
  // console.log(categories);
  // console.log(cat.data);
  const offsetCategories = 0;
  const limitCategories = 12;
  const limitCoinsPerCategory = 6;
  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["topCategoriesAndCoins"],
    queryFn: () => getTopCategoriesAndCoins(offsetCategories, limitCategories, limitCoinsPerCategory),
  });
  const categories = data?.groupedCoinsByCategory;
  return categories ? (
    <div className="bg-white pt-10 dark:bg-slate-900">
      <div className="mx-auto max-w-7xl px-2 py-36 sm:px-4 sm:pt-80 lg:px-8 lg:py-28 ">
        <div className="mb-12 text-center">
          <h2 className="text-base font-semibold uppercase tracking-wider text-cyan-600"
          data-aos="fade-down"
          >Categories</h2>
          <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-gray-50 sm:text-4xl"
          data-aos="fade-down" data-aos-delay="100"
          >Top Crypto Categories</p>
          <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500 dark:text-gray-400" data-aos="fade-down" data-aos-delay="200">
            View the largest cryptocurrency categories based on reviews experiences.
          </p>
        </div>
        {/* <div className="flex flex-row justify-between items-end mb-6">
                    <h2 className="text-black font-bold text-4xl dark:text-white">
                        Categories
                    </h2>
                    <Link href="/categories" className='-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400 dark:text-gray-300 hover:bg-slate-100 dark:hover:bg-slate-700 hover:text-gray-500 dark:hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sky-500'>view all</Link>
                </div> */}
        <div className="grid auto-rows-auto grid-cols-2 gap-4 md:grid-cols-3 lg:gap-12 xl:grid-cols-3">
          {categories.length === 0 && (
            <div className="col-span-4 flex h-96 items-center justify-center">
              <div className="h-32 w-32 animate-spin rounded-full border-b-2 border-sky-500"></div>
            </div>
          )}
          {/* Create 3 divs dynamicaly and split catgeories in 3 */}
          {[...Array(3)].map((_, i) => (
            <div className="space-y-5" key={i}>
              {Object.keys(categories)
                .slice(i * 3, i * 3 + 3)
                .map((categoryId: string) => (
                  <>
                    <div className={`relative ${i === 2 && "hidden sm:block"}`} key={categoryId} data-aos="fade-up" data-aos-delay={i * 100}>
                      <div className="relative flex items-center space-x-3 rounded-2xl border border-slate-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-sky-500 focus-within:ring-offset-2 hover:border-slate-400 dark:border-slate-800 dark:bg-slate-800 dark:focus-within:ring-offset-gray-800 dark:hover:border-slate-700">
                        <div className="min-w-0 flex-1 sm:pl-3">
                          <Link href={`/categories/${categoryId}`}>
                            <span className="absolute inset-0" aria-hidden="true" />
                            <p className="truncate text-sm font-medium text-gray-900 dark:text-gray-100">
                              {categories[categoryId][0].categoryName}
                            </p>
                          </Link>
                          {/** -translate-y-1/2 animate-movement-smooth hover:pause */}
                          <div className="mt-5 flex flex-row flex-wrap gap-4 overflow-hidden">
                            {(Array.isArray(categories[categoryId]) ? categories[categoryId] : []).map(
                              (coin: {
                                coinId: string;
                                categoryId: string;
                                name: string;
                                marketCapRank: number;
                                thumb: string;
                                small: string;
                                large: string;
                                coinName: string;
                                categoryName: string;
                              }) => (
                                <div className="inline-flex flex-shrink-0 items-center space-x-1" key={coin.coinId}>
                                  <Image src={"/img/logos/" + coin?.small || ""} alt={coin?.coinName || ""} width={30} height={30} />
                                  {/* <img className="h-10 w-10 rounded-full" src={coin.coin?.small} alt="" /> */}
                                  {/* <Link href={`/coin/${coin.coin?.name || ''}`} className="dark:text-gray-200">{coin.coin?.name || ""}</Link> */}
                                </div>
                              ),
                            )}
                            {/* <p>Find all tokens and cryptocurrencies used within the {category.name}</p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
            </div>
          ))}
        </div>
        <div className="relative" data-aos="fade-up" data-aos-delay="300">
          <div className="pointer-events-none absolute inset-x-0 bottom-0 flex justify-center bg-gradient-to-t from-white pb-8 pt-32 dark:from-slate-900">
            <Link
              href="/categories"
              className="pointer-events-auto relative flex h-12 items-center rounded-lg bg-slate-900 px-6 text-sm font-semibold text-white hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 dark:bg-slate-700 dark:hover:bg-slate-600"
            >
              Find more...
            </Link>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
