import { useQuery } from "@tanstack/react-query";
import { getStats } from "../../server/api/methods";
import Counter from "./counter";

export default function InfoBanner() {
  const { isLoading, error, data: counts, isFetching } = useQuery({ queryKey: ["stats"], queryFn: () => getStats() });


  
  return (
    <div className="bg-slate-50 pt-12 dark:bg-slate-900 sm:pt-16">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 data-aos="fade-down" className="text-3xl font-extrabold text-gray-900 dark:text-gray-50 sm:text-4xl">
            Trust Web3 is a review platform open to anyone!
          </h2>
          <p data-aos="fade-down" data-aos-delay="200" className="mt-3 text-xl text-gray-500 dark:text-gray-400 sm:mt-4">
            Share your experience.
          </p>
        </div>
      </div>
      <div className="mt-10 bg-white pb-12 dark:bg-slate-900 sm:pb-16">
        <div className="relative">
          <div className="absolute inset-0 h-1/2 bg-slate-50 dark:bg-slate-900" />
          <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-4xl">
              <dl className="rounded-lg bg-white shadow-lg dark:bg-slate-800 sm:grid sm:grid-cols-3" data-aos="fade-down" data-aos-delay="300">
                <div className="flex flex-col border-b border-gray-100 p-6 text-center dark:border-gray-700 sm:border-0 sm:border-r">
                  <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500 dark:text-gray-400">Reviews</dt>
                  <dd className="order-1 text-5xl font-extrabold text-sky-600 dark:text-sky-400">
                    {
                      counts?.countReviews && <>
                        <Counter key="countReviews" value={parseInt(counts?.countReviews) || 0} direction={'up'} />+
                      </>
                    }
                  </dd>
                </div>
                <div className="flex flex-col border-b border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r dark:border-gray-700">
                  <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500 dark:text-gray-400">Projects registered</dt>
                  <dd className="order-1 text-5xl font-extrabold text-sky-600 dark:text-sky-400">0</dd>
                </div>
                <div className="flex flex-col border-t border-gray-100 p-6 text-center dark:border-gray-700 sm:border-0 sm:border-l">
                  <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500 dark:text-gray-400">Projects</dt>
                  <dd className="order-1 text-5xl font-extrabold text-sky-600 dark:text-sky-400">
                    {
                      counts?.countCoins && <>
                        <Counter key={'countProjects'} value={parseInt(counts?.countCoins) || 0} direction={'up'} />+
                      </>
                    }
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
