// @ts-nocheck
// import { Review } from "./review"
import { useQuery } from "@tanstack/react-query";
import { getLatestReviews } from "../../server/api/methods";
import { useNetwork } from "wagmi";
import Link from "next/link";

// import { Review } from "./review";

import { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { useInView } from "framer-motion";

function StarIcon(props) {
  return (
    <svg viewBox="0 0 20 20" aria-hidden="true" {...props}>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
    </svg>
  );
}

function StarRating({ rating }) {
  return (
    <div className="flex">
      {[...Array(5).keys()].map((index) => (
        <StarIcon key={index} className={clsx("h-5 w-5", rating > index ? "fill-cyan-500" : "fill-gray-300")} />
      ))}
    </div>
  );
}

function Review({ coinName, reviewer, rating, className, review, imageNFT, coinNameId, ...props }) {
  const animationDelay = useMemo(() => {
    const possibleAnimationDelays = ["0s", "0.1s", "0.2s", "0.3s", "0.4s", "0.5s"];
    return possibleAnimationDelays[Math.floor(Math.random() * possibleAnimationDelays.length)];
  }, []);

  const content = props.content;

  return (
    <Link href={`/coin/review/${coinNameId}`} className="block">
      <figure
        className={clsx(
          "animate-fade-in rounded-3xl bg-white p-6 opacity-0 shadow-md shadow-gray-900/5 dark:border dark:border-slate-700 dark:bg-slate-900 dark:shadow-gray-900/10",
          className,
        )}
        style={{ animationDelay }}
      >
        <blockquote className="text-gray-900 dark:text-gray-100">
          <div className="flex items-center">
            <p className="text-lg font-semibold leading-6 before:content-['“'] after:content-['”']">{coinName}</p>
            <StarRating rating={rating} />
          </div>
          <p className="mt-3 text-base leading-7">{content?.title}</p>
          <p className="mt-3 text-base leading-7 whitespace-pre-wrap">{content?.comment}</p>
        </blockquote>
        <figcaption className="mt-3 text-sm text-gray-600 before:content-['–_'] dark:text-gray-400">{reviewer}</figcaption>
      </figure>
    </Link>
  );
}

function splitArray(array, numParts) {
  const result = [];

  for (let i = 0; i < array?.length; i++) {
    const index = i % numParts;
    if (!result[index]) {
      result[index] = [];
    }
    result[index].push(array[i]);
  }
  return result;
}

function ReviewColumn({ className, reviews, reviewClassName, msPerPixel = 0 }) {
  const columnRef = useRef();
  const [columnHeight, setColumnHeight] = useState(0);
  const duration = `${columnHeight * msPerPixel}ms`;

  useEffect(() => {
    const resizeObserver = new window.ResizeObserver(() => {
      setColumnHeight(columnRef.current?.offsetHeight);
    });

    resizeObserver.observe(columnRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div ref={columnRef} className={clsx("animate-marquee space-y-8 py-4", className)} style={{ "--marquee-duration": duration }}>
      {reviews.concat(reviews).map((review, reviewIndex) => {
        return (
          <>
            <Review
              key={reviewIndex}
              aria-hidden={reviewIndex >= reviews.length}
              className={reviewClassName && reviewClassName(reviewIndex % reviews.length)}
              {...review}
            />
          </>
        );
      })}
    </div>
  );
}

function ReviewGrid() {
  const { chain, chains } = useNetwork();

  const limit = 30;
  const {
    isLoading: loading,
    error,
    data: _recentReviews,
    isFetching,
  } = useQuery({ queryKey: ["latestRewiews"], queryFn: () => getLatestReviews(limit) });

  const containerRef = useRef();
  const isInView = useInView(containerRef, { once: true, amount: 0.4 });
  let columns = splitArray(_recentReviews, 3);
  columns = [columns[0], columns[1], splitArray(columns[2], 2)];

  return (
    <div>
      {/* {_recentReviews?.length > 10 && ( */}
      <div
        ref={containerRef}
        data-aos="fade-down"
        data-aos-delay="200"
        className="relative -mx-4 mt-16 grid h-[49rem] max-h-[150vh] grid-cols-1 items-start gap-8 overflow-hidden px-4 sm:mt-20 md:grid-cols-2 lg:grid-cols-3"
      >
        {isInView && columns.length && columns?.[0]?.length && columns?.[1]?.length && columns?.[2].length && (
          <>
            <ReviewColumn
              key={0}
              reviews={[...columns[0], ...columns[2].flat(), ...columns[1]]}
              reviewClassName={(reviewIndex) =>
                clsx(
                  reviewIndex >= columns[0].length + columns?.[2]?.[0]?.length && "md:hidden",
                  reviewIndex >= columns[0].length && "lg:hidden",
                )
              }
              msPerPixel={10}
            />
            {
              <ReviewColumn
                key={1}
                reviews={[...columns[1], ...columns[2][1]]}
                className="hidden md:block"
                reviewClassName={(reviewIndex) => reviewIndex >= columns[1].length && "lg:hidden"}
                msPerPixel={15}
              />
            }
            <ReviewColumn key={2} reviews={columns[2].flat()} className="hidden lg:block" msPerPixel={10} />
          </>
        )}
        <div className="pointer-events-none absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-50 dark:from-slate-900" />
        <div className="from-gray-5 dark:from-slate-9000 pointer-events-none absolute inset-x-0 bottom-0 h-32 bg-gradient-to-t" />
      </div>
      {/* )} */}
    </div>
  );
}

const Container = ({ className, ...props }) => {
  return <div className={clsx("mx-auto max-w-7xl px-4 sm:px-6 lg:px-8", className)} {...props} />;
};

export default function RecentReviews() {
  return (
    <section id="reviews" aria-labelledby="reviews-title" className="bg-gray-50 pb-16 pt-20 dark:bg-slate-900 sm:pb-24 sm:pt-48">
      <Container>
        <h2
          id="reviews-title"
          data-aos="fade-down"
          className="font-display bg-gradient-to-r from-indigo-200 via-sky-400 to-indigo-200 bg-clip-text text-3xl font-medium tracking-tight text-gray-900 text-transparent sm:text-center"
        >
          Everyone is changing their life with <span className="font-bold">Web3Trust</span>.
        </h2>
        <p
          data-aos="fade-down"
          data-aos-delay="100"
          className="mt-2 text-lg text-gray-600 dark:text-gray-400 sm:text-center">Trust comes easier when you see trustable reviews.</p>
        <ReviewGrid />
      </Container>
    </section>
  );
}
