const posts: any[] = [
  // {
  //   title: "Boost your conversion rate",
  //   href: "#",
  //   category: { name: "Article", href: "#" },
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium praesentium eius, ut atque fuga culpa, similique sequi cum eos quis dolorum.",
  //   date: "Mar 16, 2020",
  //   datetime: "2020-03-16",
  //   imageUrl:
  //     "https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80",
  //   readingTime: "6 min",
  //   author: {
  //     name: "Roel Aufderehar",
  //     href: "#",
  //     imageUrl:
  //       "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  //   },
  // },
  // {
  //   title: "How to use search engine optimization to drive sales",
  //   href: "#",
  //   category: { name: "Video", href: "#" },
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit facilis asperiores porro quaerat doloribus, eveniet dolore. Adipisci tempora aut inventore optio animi., tempore temporibus quo laudantium.",
  //   date: "Mar 10, 2020",
  //   datetime: "2020-03-10",
  //   imageUrl:
  //     "https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80",
  //   readingTime: "4 min",
  //   author: {
  //     name: "Brenna Goyette",
  //     href: "#",
  //     imageUrl:
  //       "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  //   },
  // },
  // {
  //   title: "Improve your customer experience",
  //   href: "#",
  //   category: { name: "Case Study", href: "#" },
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint harum rerum voluptatem quo recusandae magni placeat saepe molestiae, sed excepturi cumque corporis perferendis hic.",
  //   date: "Feb 12, 2020",
  //   datetime: "2020-02-12",
  //   imageUrl:
  //     "https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80",
  //   readingTime: "11 min",
  //   author: {
  //     name: "Daniela Metz",
  //     href: "#",
  //     imageUrl:
  //       "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  //   },
  // },
];

export default function Blog() {
  if (posts.length === 0) return <></>;

  return (
    <div className="relative bg-gray-50 px-4 pb-20 pt-16 sm:px-6 lg:px-8 lg:pb-28 lg:pt-24 dark:bg-gray-900">
      {/* <div className="absolute inset-0">
        <div className="h-1/3 bg-white sm:h-2/3 dark:bg-gray-800" />
      </div> */}
      <div className="relative mx-auto max-w-7xl">
        <div className="text-center">
          <h2 className="text-base font-semibold uppercase tracking-wider text-cyan-600" data-aos="fade-down">Latest News</h2>
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl dark:text-gray-100">From the blog</h2>
          <p className="mx-auto mt-3 max-w-3xl text-xl text-gray-500 sm:mt-4 dark:text-gray-300">
            Dive into a world where reviews are transparent and decentralized. Discover, and contribute to reviews of top web3 projects. Be part of the blockchain revolution.
          </p>
        </div>
        <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <div key={post.title} className="flex flex-col overflow-hidden rounded-lg shadow-lg">
              <div className="flex-shrink-0">
                <img className="h-48 w-full object-cover" src={post.imageUrl} alt="" />
              </div>
              <div className="flex flex-1 flex-col justify-between bg-white dark:bg-gray-800 p-6">
                <div className="flex-1">
                  <p className="text-sm font-medium text-sky-600">
                    <a href={post.category.href} className="hover:underline">
                      {post.category.name}
                    </a>
                  </p>
                  <a href={post.href} className="mt-2 block">
                    <p className="text-xl font-semibold text-gray-900 dark:text-gray-100">{post.title}</p>
                    <p className="mt-3 text-base text-gray-500 dark:text-gray-300">{post.description}</p>
                  </a>
                </div>
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <a href={post.author.href}>
                      <span className="sr-only">{post.author.name}</span>
                      <img className="h-10 w-10 rounded-full" src={post.author.imageUrl} alt="" />
                    </a>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900 dark:text-gray-100">
                      <a href={post.author.href} className="hover:underline">
                        {post.author.name}
                      </a>
                    </p>
                    <div className="flex space-x-1 text-sm text-gray-500 dark:text-gray-300">
                      <time dateTime={post.datetime}>{post.date}</time>
                      <span aria-hidden="true">&middot;</span>
                      <span>{post.readingTime} read</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
